import React, { FC } from "react";
import styled from "styled-components";
import Link from "./customLink";

const StyledWrapperDiv = styled.div`
  background: rgb(75, 46, 131);
  height: 90px;
  margin-bottom: 1.45rem;
`;

const StyledContentDiv = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 1.45rem 1.0875rem;
`;

const NameHeader = styled.h1`
  font-family: "Raleway", sans-serif;
  margin: 0;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

export const Header: FC = () => (
  <StyledWrapperDiv>
    <StyledContentDiv>
      <NameHeader>
        <StyledLink to="/">Trevor Shibley</StyledLink>
      </NameHeader>
    </StyledContentDiv>
  </StyledWrapperDiv>
);
