import React, { FC, ReactNode } from "react";
import Helmet from "react-helmet";
import "./base.css";
import { Header } from "./header";
import styled from "styled-components";

const StyledPageContent = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
`;

interface WrapperProps {
  children: ReactNode;
}

export const Layout: FC<WrapperProps> = ({ children }) => (
  <div>
    <Helmet
      title="Trevor Shibley"
      meta={[
        { name: "description", content: "Portfolio" },
        { name: "keywords", content: "trevor, shibley, tshibley, portfolio" },
      ]}
    />
    <Header />
    <StyledPageContent>{children}</StyledPageContent>
  </div>
);
